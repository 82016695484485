.onboarding-message {
  width: 80%;
  max-width: 42.5rem;
  grid-column: content;
  margin: 3.2rem 0 1.5rem 0;
  letter-spacing: -0.011rem;

  h1 {
    margin: 0 1.563rem 1.875rem 0;
    font-weight: 700;
    font-size: 2.25rem;
    letter-spacing: -0.023rem;
  }

  p {
    margin: 0;
    font-size: 1.125rem;
  }

  p + p {
    margin-top: 1.125rem;
  }

  @media (min-width: 74em) {
    h1 {
      font-size: 3.125rem;
      letter-spacing: -0.011rem;
    }
  }
}
