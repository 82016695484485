:root {
  --bonnier-blue-100: #d0eaf4;
  --bonnier-blue-200: #a1d5e9;
  --bonnier-blue-300: #72c0de;
  --bonnier-blue-400: #43abd3;
  --bonnier-blue-500: #1496c8;
  --bonnier-blue-600: #0a6ba3;
  --bonnier-blue-700: #00407d;
  --bonnier-gray-100: #f4f2f0;
  --bonnier-gray-200: #d3d1d1;
  --bonnier-gray-300: #b2b1b1;
  --bonnier-gray-400: #909092;
  --bonnier-gray-500: #6f6f72;
  --bonnier-gray-600: #2e2c2a;
  --bonnier-gray-700: #222222;
  --bonnier-green-500: #56827f;
  --bonnier-pink-500: #d35098;
  --bonnier-white: #ffffff;
  --border-radius: 16px;
  --color-text: var(--bonnier-gray-700);
  --color-border: #ddd;
  --color-error: #d60505;
  --color-plusallt: #fde400;
  --color-surface: var(--bonnier-gray-100);
  --color-background: #fff;
  --spacing: 16px;
  --spacing-large: 1.875rem; /* 30px */
  --fs-small: 0.81rem; /* 13px */
  --fs-xs: 0.75rem; /* 12px */
  --fs-article-title-small: 1rem; /* 16px */
  --fs-article-body-text: 1rem; /* 16px */
  --fs-teaser-text: 0.94rem; /* 15px */
}
