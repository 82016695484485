.brand {
  display: flex;
  align-items: center;
  gap: 8px;
  color: inherit;
  margin-bottom: var(--brand-margin-bottom, 0.1875rem);

  img {
    width: 30px;
    height: 30px;
  }

  .brand-title {
    margin-bottom: 0;
  }
}

/* Can be used both for single brands with image, or when brand is displayed as text only */
.brand-title {
  color: var(--bonnier-gray-700);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.428571;
}
