.btn {
  --_button-align-items: var(--button-align-items, center);
  --_button-bg-color: var(--button-bg-color, var(--bonnier-gray-700, black));
  --_button-border-radius: var(--button-border-radius, 999px);
  --_button-border: var(--button-border, none);
  --_button-disabled-bg-color: var(--button-disabled-bg-color, var(--bonnier-gray-100, black));
  --_button-disabled-text-color: var(--button-disabled-text-color, var(--bonnier-gray-300, black));
  --_button-display: var(--button-display, inline-flex);
  --_button-font-size: var(--button-font-size, 1rem);
  --_button-gap: var(--button-gap, 0.25em);
  --_button-hover-bg-color: var(--button-hover-bg-color, var(--bonnier-gray-600, black));
  --_button-hover-text-color: var(--button-hover-text-color, white);
  --_button-justify-content: var(--button-justify-content, center);
  --_button-line-height: var(--button-line-height, 1.5);
  --_button-padding-block: var(--button-padding-block, 0.75em);
  --_button-padding-inline: var(--button-padding-inline, 1.5em);
  --_button-pressed-bg-color: var(--button-pressed-bg-color, var(--bonnier-gray-500, black));
  --_button-pressed-text-color: var(--button-pressed-text-color, white);
  --_button-text-color: var(--button-text-color, white);
  align-items: var(--_button-align-items);
  background-color: var(--_button-bg-color);
  border-radius: var(--_button-border-radius);
  border: var(--_button-border);
  color: var(--_button-text-color);
  cursor: pointer;
  display: var(--_button-display);
  font-size: var(--_button-font-size);
  gap: var(--_button-gap);
  justify-content: var(--_button-justify-content);
  line-height: var(--_button-line-height);
  padding: var(--_button-padding-block) var(--_button-padding-inline);
  text-align: center;
  text-decoration: none;
  transition-property: background-color, color;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  outline: 1px solid transparent;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  & svg {
    fill: currentColor;
  }
}

.btn:hover {
  background-color: var(--_button-hover-bg-color);
  color: var(--_button-hover-text-color);
}

.btn:active,
.btn[aria-pressed="true"] {
  background-color: var(--_button-pressed-bg-color);
  color: var(--_button-pressed-text-color);
}

.btn:disabled {
  background-color: var(--_button-disabled-bg-color);
  color: var(--_button-disabled-text-color);
}

.btn:focus-visible {
  outline: 1px solid transparent;
  box-shadow: 0 0 0 0.1875rem var(--color-bg, white), 0 0 0 0.375rem var(--bonnier-blue-500, blue);

  @media (forced-colors: active) {
    outline: 2px solid Highlight;
  }
}

.btn--small {
  --button-font-size: 1em; /* 16px */
  --button-padding-block: 0.5em; /* 6px */
  --button-padding-inline: 0.75em; /* 12px */
}

.btn--secondary {
  --button-bg-color: var(--bonnier-gray-100);
  --button-text-color: var(--bonnier-gray-700);
  --button-pressed-bg-color: var(--bonnier-gray-200);
  --button-hover-bg-color: var(--bonnier-gray-200);
  --button-hover-text-color: var(--bonnier-gray-700);
}

.btn--ghost {
  --button-bg-color: transparent;
  --button-text-color: var(--bonnier-gray-700);
  --button-hover-bg-color: rgba(88, 84, 65, 0.16);
  --button-hover-text-color: var(--bonnier-gray-700);
}

.btn--block {
  --button-display: flex;
}

.btn--light {
  --button-bg-color: var(--bonnier-white);
  --button-text-color: var(--bonnier-gray-700);
  --button-pressed-bg-color: var(--bonnier-gray-200);
  --button-hover-color: var(--bonnier-gray-100);
  --button-disabled-bg-color: var(--bonnier-white);
  --button-disabled-text-color: var(--bonnier-gray-500);
}

.unbtn {
  background: none;
  border: none;
  font: inherit;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}
