.source-settings {
  max-width: 31rem;

  h2 {
    margin: 0;
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: -0.014rem;

    strong {
      font-weight: 700;
    }
  }

  .sub-heading {
    font-weight: normal;
    display: inline-block;
    margin: 1.875rem 0 0;
  }

  h3 {
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 0.3125rem;
  }

  h4 {
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 0.875rem;
  }

  h3 + .source-settings__subsection > h4 {
    margin-top: 0.875rem;
  }
}

.source-settings__header {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.375rem;
  margin: 3.125rem 0 1.875rem 0;

  @media (min-width: 45.25em) {
    max-width: initial;
  }
}

.source-settings__list {
  list-style: none;
  padding: 0;
  margin-block: 0.875rem;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      align-items: center;
    }

    img {
      height: 32px;
      width: 32px;
      margin-right: .75rem;
      border-radius: 50%;
    }
  }

  li + li {
    margin-top: 0.75rem;
  }
}

