.navigation-status {
  grid-column: fullwidth;
  width: 100%;
  display: grid;
  grid-template-columns: inherit;
  border-bottom: 1px solid var(--color-border);
  margin-top: 1.5rem;
  
  > p {
    grid-column: content;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0;
    padding-bottom: 0.5rem;

    strong {
      font-weight: 500;
    }
  }
}
