.article {
  contain: layout;

  display: grid;
  grid-template-rows:
    [source-start full-start summary-clickable-start]
    minmax(2.875rem, auto) /* 46px source = 30px plus 0.5rem padding top and bottom */
    [source-end header-start]
    minmax(0, auto)
    [header-end summary-clickable-end body-start]
    minmax(0, auto)
    [body-end image-start]
    minmax(0, auto)
    [image-end]
    minmax(0, auto)
    [body-end questions-start]
    minmax(0, auto)
    [questions-end related-start]
    minmax(0, auto)
    [related-end spacer-start]
    minmax(0, auto)
    [spacer-end full-end];

  --content-width: minmax(30px, calc(100% - (15px + 22px + 8px)));

  --clickable-surface-grid-area: summary-clickable / content;

  --article-source-image-size: 2.25rem;
  --article-timeline-item-gap: 1.25rem;
  --article-timeline-columns: calc(var(--article-source-image-size) + 6px) 1fr;

  grid-template-columns: [content-start] 1fr [content-end];

  max-width: 42.5rem;

  --_article-headline-fs: var(--article-headline-fs, 1.5rem); /* default 24px */
  --_article-headline-fs-large: var(--article-headline-fs-large, 2.125rem); /* default 34px */

  a:any-link {
    text-decoration: var(--article-link-decoration, none);
    color: inherit;

    &:hover {
      --article-link-decoration: underline;
    }
  }

  &:has(.clickable-surface__hidden-link:hover) .clickable-surface__link {
    --article-link-decoration: underline;
  }

  > * {
    grid-column: content;
  }
}

.article-figure {
  position: relative;

  /* Hide any caption text for now. */
  figcaption p {
    display: none;
  }

  .image-byline {
    position: absolute;
    bottom: 0.3125rem;
    right: 0.3125rem;
    padding: 0.25rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.5625rem;
  }
}

.article-figure--featured {
  grid-row: image;
}

.article-featured-img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 6px;
}

.article-header {
  grid-row: header;
  margin-bottom: 1.188rem;
}

.article-header__headline {
  margin: 0 0 0.625rem 0;
  font-size: var(--_article-headline-fs);
  text-wrap: balance;
  text-wrap: pretty;

  @media (min-width: 45.25em) {
    font-size: var(--_article-headline-fs-large);
    margin-bottom: 0.875rem;
  }
}

.article-header__vignette {
  font-weight: 500;
  font-size: var(--fs-small);
  line-height: 1.385; /* ≈ 18px */
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  margin: 0 0 0.625rem 0;
}

.article-source {
  grid-row: 1;
  align-items: center;
  color: var(--bonnier-gray-700);
  font-size: var(--fs-small);
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 0.875rem;
  margin-bottom: .625rem;

  .article-source__logos {
    display: flex;
  }

  img {
    width: var(--article-source-image-size);
    height: var(--article-source-image-size);
    border: 3px solid var(--color-background);
    border-radius: 50%;
    background-color: transparent;
  }

  img + img {
    margin-left: calc(var(--article-source-image-size) * -0.3);
  }
}

.article-source--with-logos {
  margin-bottom: 1.5rem;
}

/* Helper class to generate the timeline look */
.article-timeline::after {
  content: "";
  grid-column: 1;
  grid-row: 1 / -1;
  justify-self: center;
  align-self: start;
  position: relative;
  top: calc(var(--full-button-height, 0) / 2);
  width: 1px;
  background-clip: content-box;
  border-left: 1px solid transparent; /* Note: used for high-contrast mode accessibility */
  height: calc(
    100% + var(--article-timeline-item-gap) -
    var(--article-timeline-offset-start, 0px)
  );
  background-color: var(--bonnier-gray-200);
}

.article-body {
  grid-row: body;
  margin-bottom: 0.625rem;

  --button-border: 1px solid var(--bonnier-gray-200);
  --button-font-size: 0.875rem; /* 14px */
  --button-line-height: 1.25; /* 20px */
  --button-padding-block: 0.4286em; /* 6px */
  --full-button-height: calc(
    var(--button-font-size) * var(--button-line-height) +
    var(--button-padding-block) * 2
  );

  /* Timeline doesn't start from top, so we figure out how far is needed to offset it */
  --article-timeline-offset-start: calc(var(--full-button-height) / 2);

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.article-body--expandable {

}

.article-body__details {
  display: flex;
  flex-direction: column;
}

.article-body__trigger {
  flex: 1;
  display: flex;
  /* grid-area: trigger;
  justify-self: start; */
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.428571;
  --_button-font-size: var(--fs-small);
  --_button-text-color: #000;

  /* these following two rules hide the marker: */
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }

  @media (min-width: 45.25em) {
    font-size: 1rem;
  }

  &:hover {
    svg {
      background-color: #E1E1E1;
    }
  }
}

.article-body__details[open] .article-body__summary-label-close {
  display: initial;
}

.article-body__trigger svg {
  display: inline-block;
  margin-left: 0.25rem;
  vertical-align: -26%;
  transform-origin: 50% 50%;
  padding: 0.375rem;
  border-radius: 50%;
  margin: auto 0.5rem auto auto;

  @media (min-width: 45.25em) {
    vertical-align: -23%;
  }
}

.article-body__details[open] .article-body__trigger svg {
  transform: rotate(180deg);
}

.article-body__details[open] .article-body__summary-label {
  display: none;
}

.article-body__summary-label {
  margin: auto 0;
}

.article-body__summary-label-close {
  margin: auto 0;
}

.article-related-item__content {
  img {
    border-radius: 6px;
    max-width: calc(100% - 30px);
    height: auto;
    margin-top: 1.25rem;
  }

  .image-byline {
    right: calc(30px + 0.3125rem);
  }
}

.article-body__summary {
  margin-top: 0.875rem;
  padding: 1.25rem;
  background-color: var(--bonnier-gray-100);
  border-radius: 6px;
  font-size: 0.875rem;
  line-height: 1.5;

  :where(p, ul, li, blockquote) {
    margin: 0;
  }
  * + * {
    margin-top: 0.75rem;
  }
}

.article-preamble,
.article-body--non-expandable {
  margin: 0;
  font-size: var(--fs-article-body-text);
}

.article-body--non-expandable p {
  line-height: 1.5;
  font-size: 1.1rem;
  font-family: Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  + p {
    margin-top: 1em;
  }
}

.article-preamble {
  p {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
    font-family: Georgia, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.article-related {
  grid-row: related;
  margin-top: var(--article-timeline-item-gap);
  margin-bottom: 0;
  padding: 0;
}

.article-related-item {
  list-style: none;
  display: grid;
  grid-template-areas:  "brand meta"
                        ". content";
  grid-template-columns: var(--article-timeline-columns);
  gap: 0 10px;
  position: relative;
  text-decoration: none;

  & + & {
    margin-top: var(--article-timeline-item-gap);
  }

  &:hover {
    --article-link-decoration: underline;
  }

  .brand-circle {
    grid-area: brand;
    width: var(--article-source-image-size);
    height: var(--article-source-image-size);
    border: 3px solid var(--color-background);
    border-radius: 50%;
    z-index: 1;
  }

  .article-related-item__meta {
    display: flex;
    align-items: center;
    column-gap: .625rem;
  }

  .article-related-item__content {
    grid-area: content;

    p {
      margin: 0;
      font-size: 1.1rem;
      line-height: 1.5;
      font-family: Georgia, serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    p + p {
      margin-top: 1em;
    }

    p:first-of-type {
      margin-top: 0;
    }
  }

  .article-related-title {
    margin-top: 0;
    line-height: 1.3;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0.44rem; /* 7px */

    @media (min-width: 45.25em) {
      font-size: 1.375rem;
    }

    a {
      color: var(--bonnier-gray-700);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.article-feedback-buttons {
  grid-row: spacer;
  margin-top: 2rem;
}

.unhandled-block {
  background-color: lime;

  .code {
    display: block;
    padding: 10px;
    background-color: black;
    color: lime;
    overflow-x: scroll;
  }
}
