.feed {
  margin-top: 1rem;
}

.feed-item {
  grid-column: content;
  padding-bottom: 1.25rem;

  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: fullwidth;

    > * {
      grid-column: content;
    }

    @media (min-width: 45.5rem) {
      /* Use a "breakout" layout for the item, shifting it outside the normal flow,
       while the contents sort of shift back to the content area inside. */
      grid-column: breakout-start / content-end;
    }
  }
}

* + .feed-item,
.feed-item + * > .feed-item:first-child {
  border-top: 1px solid var(--color-border);
  padding-top: 1.25rem;
}

.feed-item:empty {
  display: none;
}
