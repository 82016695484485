feedback-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 0.625rem;
  align-items: center;
  background-color: var(--bonnier-blue-100);
  padding: 0.875rem 0.75rem 0.875rem 1rem;
  border-radius: 6px;

  --button-font-size: 1rem;
  --button-border-radius: 0.625em;
  --button-text-color: var(--bonnier-blue-600);
  --button-bg-color: var(--bonnier-white);
  --button-padding-block: 0.5625em;
  --button-padding-inline: 1em;
  --button-hover-bg-color: var(--bonnier-blue-500);
  --button-hover-text-color: var(--bonnier-white);
  --button-pressed-bg-color: var(--button-hover-bg-color);
  --button-pressed-text-color: var(--button-hover-text-color);

  > div {
    white-space: nowrap;

    button + button {
      margin-left: 0.625rem;
    }
  }

  p {
    font-size: var(--fs-small);
    line-height: 1.4;
    margin: 0;
    flex: 1 1 min-content;
  }

  &:not(:defined) {
    visibility: hidden;
  }

  svg {
    display: block;
  }
}
