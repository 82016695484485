source-toggle-button {
  button {
    width: 74px;
    height: 28px;
    border-radius: 4px;
    font-size: .75rem;
    cursor: pointer;

    &:hover {
      background: #F8F7F6;
    }
  }

  /* This animation runs on page load, which is okay with such a subtle animation, but we should fix that wit js in the future. */
  button:not([hidden]) {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .add-button {
    color: black;
    border: 1px solid #B0B0B0;
    background-color: #FFFFFF;
  }

  .remove-button {
    color: #FFFFFF;
    border: 1px solid #000000;
    background: #222222;

    &:hover {
      color: black;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
