.q-a-list {
  margin-top: .875rem;
  grid-row: questions;
}

.q-a {
  margin-top: 0.875rem;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.875rem 0;
  border-bottom: 1px solid #E1E1E1;
}

.q-a__question {
  flex: 1;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.428571;
  color: #222222;
  --_button-font-size: var(--fs-small);
  --_button-text-color: #222222;

  /* these following two rules hide the marker: */
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }

  svg {
    padding: 0.375rem;
    border-radius: 50%;
    margin: auto 0 auto auto;
  }

  &:hover {
    svg {
      background-color: #E1E1E1;
    }
  }
}

.q-a[open] {
  .q-a__question {
    svg {
      transform: rotate(180deg);
    }
  }
}

.q-a__question-text {
  flex: 1;
  margin: auto 0;
}

.q-a__answer {
  font-size: 1rem;
  margin-top: 0.875rem;
  line-height: 1.5;

  a:any-link {
    text-decoration: underline;
  }

  > * {
    margin: 0;
  }
}
