@font-face {
  font-family: InterVariable;
  src: url("../../fonts/Inter.var.woff2") format("woff2-variations");
  font-weight: 100 900;
  font-display: swap;
}

@font-face {
  font-family: "Inter-fallback";
  size-adjust: 107.00%;
  ascent-override: 90%;
  src: local("Arial");
}
