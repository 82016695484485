/**
 * "Sticky footer" solution for more modern browsers.
 * Older browsers not supporting :has will just see a footer directly after content where pages are too short.
 */
body:has(> .site-footer--sticky) {
  height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
  }
}

.site-footer {
  background-color: var(--bonnier-gray-700);
  color: var(--bonnier-white);
  margin-top: var(--spacing-large);
  padding: var(--spacing-large) 0;

  & button,
  & a {
    color: inherit;
  }

  p {
    margin: 0;
    & + p {
      margin-top: 1.125rem;
    }
  }
}

.site-footer-content {
  padding: 0 1.125rem;
  font-size: var(--fs-small);
}

/**
 * "Sticky footer" solution for more modern browsers.
 * Older browsers not supporting :has will just see a footer directly after content where pages are too short.
 */
body:has(> .site-footer--sticky) {
  height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
  }
}
