:root {
  --site-content-inline-gap: 0.875rem; /* on screens smaller than the max width, we need extra space on the sides */
  --site-content-maxwidth: calc(74rem - (var(--site-content-inline-gap, 0rem) * 2));
  --site-header-padding-top: 1rem;
  --_site-header-icon-size: var(--site-header-icon-size, 2rem);

  @media (min-width: 61.25em) {
    --site-header-padding-top: 2.25rem;
  }
}

.site-header {
  display: grid;
  padding-top: var(--site-header-padding-top);
  grid-template-columns: [fullwidth-start] minmax(var(--site-content-inline-gap), 1fr) [content-start] minmax(auto, var(--site-content-maxwidth)) [content-end] minmax(var(--site-content-inline-gap), 1fr) [fullwidth-end];
  justify-content: center;
  justify-items: start;
  align-items: center;
}

.site-header--with-background {
  background-color: #F2EFFC;
}

.site-header__top {
  /* respect side spacing on smaller screens */
  grid-column: content;
  grid-row: 1;
  max-width: 42.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 25em) {
    --_site-header-icon-size: 1.5rem;
    .btn--small {
      --button-padding-inline: 0.35rem;
      --button-padding-block: 0.25rem;
      --button-font-size: 0.875rem;
    }
  }

  /* when max-width is reached, make the top part fill the full width */
  @media (min-width: 74rem) {
    grid-column: content;
  }
}

.site-header--article .site-header__top,
.site-header--settings .site-header__top {
  grid-column: content;
}

.site-header__logo {
  margin: 0;
  grid-area: logo;
  justify-self: flex-start;
  display: flex;
  position: relative;

  & a {
    display: flex;
    gap: 6px;
    text-decoration: none;
    color: inherit;
    margin-bottom: 13%;
    transition: box-shadow 0.3s ease-in-out;
  }

  .plus-allt-logo {
    display: block;
    & path {
      fill: currentColor;
    }

    @media (min-width: 74em) {
      width: 90px;
    }
  }
}

.site-header__back {
  display: flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 1rem;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.site-header__user {
  display: flex;
  align-items: center;
  svg {
    fill: none;
    width: var(--_site-header-icon-size);
    height: var(--_site-header-icon-size);
  }
}

.site-header__nav {
  grid-column: fullwidth;
  grid-row: 2;
  width: 100%;
  overflow-x: hidden;
  margin-top: 1.875rem;

  @media (min-width: 74rem) {
    grid-column: content;
  }
}
