body {
  background-color: var(--color-background);
  font-family: InterVariable, "Inter-Fallback", sans-serif;
  margin: 0;
}

figure {
  margin: 0;
}

i, em {
  @supports (font-variation-settings: "slnt" -10) {
    font-style: normal;
    font-variation-settings: "slnt" -10;
  }
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.container {
  --half-gap: calc(var(--site-content-inline-gap) / 2);
  --side-gap-rest: minmax(var(--half-gap), 1fr);
  --side-gap-breakout: minmax(var(--half-gap), 1fr);
  @media (min-width: 80.25rem) {
    --side-gap-breakout: minmax(var(--half-gap), 3.125rem);
  }
  display: grid;
  grid-template-columns: [fullwidth-start] var(--side-gap-rest) [breakout-start] var(--side-gap-breakout) [content-start] minmax(auto, var(--site-content-maxwidth)) [content-end] var(--side-gap-breakout) [breakout-end] var(--side-gap-rest) [fullwidth-end];
  justify-content: center;
  > * {
    grid-column: content;
  }
}

.container--main {
  flex-grow: 1; /* Sticky footer */
}

.clickable-surface {
  position: relative;

  /**
   * 1. Since we remove the outline of the link inside the element, and the border
   *    color stays the same in forced-colors mode, we need to take care of
   *    a special color for that mode. Uses system color keyword.
   */
   &:where(:focus-within) {
    outline: 2px auto -moz-mac-focus-ring;
    outline: 2px auto -webkit-focus-ring-color;
    /* box-shadow: 0 0 0 4px #fff; */
    @media (forced-colors: active) {
      outline-color: Highlight; /* See 1. */
    }
  }
  /**
   * 1. The rule for focus within could have acheived the following, but
   *    support for :has() is not great yet, so this rule negates the previous one
   *    where the link should not have visible focus. This prevents a flash of outline
   *    in browsers supporting :has().
   */
  &:where(:focus-within:not(:has(.clickable-surface__link:focus-visible))) {
    outline: none; /* See 1. */
    box-shadow: none;
  }

  /**
   * 1. Since focus is drawn on the card using :focus-within,
   *    we don't need focus styles on the actual call to action.
   * 2. Makes sure that the link is on top of the card.
   */
  .clickable-surface__link {
    &:where(:focus-visible) {
      outline: none; /* See 1. */
      box-shadow: none;
    }
  }



  &:hover {
    --default-link-decoration: underline;

    /* revert for links in override */
    & .clickable-surface__override {
      --default-link-decoration: revert;
    }

    /* in supported browsers: revert when hovering override */
    &:has(.clickable-surface__override:hover) {
      --default-link-decoration: revert;
    }
  }
}

.clickable-surface__hidden-link {
  grid-area: var(--clickable-surface-grid-area, 1 / 1 / -1 / -1);
  color: transparent !important;
  text-indent: 200% !important;
  white-space: nowrap !important;
  overflow: hidden;
  position: relative;
}

.clickable-surface__override {
  position: relative;
  z-index: 2;
}

.headline {
  color: var(--bonnier-gray-700);
  font-weight: 700;
  line-height: 1.15;
  overflow-wrap: break-word;
}

.article-title-small {
  display: block;
  color: var(--bonnier-gray-700);
  font-weight: 800;
  font-size: var(--fs-article-title-small);
  margin: 0;
  text-decoration: var(--article-link-decoration, none);
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
}

.time {
  font-size: var(--fs-xs);
  color: var(--bonnier-gray-500);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.spinner {
  display: block;
  width: 30px;
  height: 30px;
  margin: 10px auto;
  border-radius: 50%;
  border: 3px solid rgb(107 107 107 / 30%);
  border-top-color: #000000;
  animation: spin 1s ease-in-out infinite;
}
